<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Редактировать</h4>
          </div>
          <div class="card-body">
            <Form v-if="countryData !== null" class="row mt-4" @submit="update" v-slot="{ meta }">
              <div class="col-md-4">
                <label for="name" class="form-label">Название (English)</label>
                <Field
                  v-model="countryData.name.eng"
                  id="name"
                  label="Название (English)"
                  name="names[eng]"
                  type="text"
                  class="form-control"
                  rules="required"
                />
                <ErrorMessage name="name-en" class="text-danger" />
              </div>

              <div class="col-md-4">
                <label for="name" class="form-label">Название (O’zbek)</label>
                <Field
                  v-model="countryData.name.uz"
                  id="name"
                  type="text"
                  class="form-control"
                  name="names[uz]"
                  rules="required"
                />
                <ErrorMessage name="name-uz" class="text-danger" />
              </div>

              <div class="col-md-4">
                <label for="name" class="form-label">Название (Русский)</label>
                <Field
                  v-model="countryData.name.ru"
                  id="name"
                  type="text"
                  class="form-control"
                  name="names[ru]"
                  rules="required"
                />
                <ErrorMessage class="text-danger" name="name-ru" />
              </div>

              <div class="mt-4"></div>

              <div class="col-md-4">
                <label for="phone" class="form-label">Номер контактного лица</label>
                <Field
                  v-model="countryData.phone"
                  id="phone"
                  type="tel"
                  class="form-control"
                  rules="required"
                  name="phone"
                />
                <ErrorMessage class="text-danger" name="phone" />
              </div>

              <div class="col-md-4">
                <label for="country-photo" class="form-label">
                  Фото страны
                </label>

                <div v-if="!showFileInput" class="d-flex flex-column align-items-center">
                  <img :src="countryData.image" alt="country photo" width="150">
                  <button class="btn btn-primary w-100 mt-2" type="button" @click.prevent="showFileInput = true">Изменить</button>
                </div>

                <template v-else>
                  <Field
                    v-model="countryData.image"
                    id="country-photo"
                    type="file"
                    class="form-control"
                    rules="required"
                    name="image"
                  />
                  <ErrorMessage class="text-danger" name="image" />
                </template>
              </div>

              <div class="col-md-4">
                <label for="cost" class="form-label">Стоимость доставки</label>
                <Field
                  v-model="countryData.price"
                  id="cost"
                  type="number"
                  class="form-control"
                  rules="required"
                  name="price"
                />
                <ErrorMessage class="text-danger" name="price" />
              </div>

              <div class="mt-4"></div>

              <div class="text-center col-12">
                <button :disabled="!(meta.valid && meta.dirty)" type="submit" class="btn btn-primary">Сохранить</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { getCountry, updateCountry } from '../../../services/countries.service'

export default {
  name: 'CountriesIndex',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()

    const countryId = route.params.id
    const countryData = ref(null)
    const showFileInput = ref(false)

    const update = async (formValues) => {
      try {
        const formData = new FormData()

        formData.append('id', countryId)
        Object.entries(formValues).forEach(([name, value]) => {
          formData.append(name, value)
        })

        const response = await updateCountry(formData)

        if (response.status === 'error') {
          toast.error('Error')
          return
        }

        router.push({ name: 'default.countries' })
      } catch (e) {
        Object.entries(e.response.data.error).forEach(([field, errorText]) => {
          toast.error(errorText[0])
        })
      }
    }

    onMounted(async () => {
      const { data: country } = await getCountry(countryId)

      countryData.value = country
    })

    return {
      showFileInput,
      countryData,
      update
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage
  }
}
</script>
